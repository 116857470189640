//**********************************************
//***** DECLARATIONS
//**********************************************

//------- Couleurs -------//

// Ne changent jamais :
@blanc: #fff;
@gris-tresclair: #f5f5f5;
@gris-clair: #ccc;
@gris-moyenclair: #999;
@gris-fonce: #333;
@gris-tresfonce: #1a1a18;
@noir: #000;

// Couleurs réseaux sociaux :
@facebook: #3B5998;
@twitter: #00ACED;
@instagram: #517FA4;
@youtube: #BB0000;
@linkedin: #007BB6;
@google: #DD4B39;

// Couleurs supplémentaires :
@rouge: #AA102A;
@bleu-ciel: #66afe9;
@marron: #8C4653;
@beige: #faf6ed;

// Couleurs spécifiques :
@texte-principal: #333;

@error: #ff0000;

// Précisions : pour faire en rgba mettre comme ça : fade(@noir, 70%); => (0,0,0,.7)


//------- Fonts -------//

@font-base: 'Old Standard TT', serif;
@font-spe: 'Old Standard TT', serif;

//------- Classes abstraites -------//

.border-radius (@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}
// Ex utilisation :
//#id {
//	.border-radius(10px);
//}

.box-shadow (@x, @y, @blur, @spread, @r, @v, @b, @alpha) {
	-webkit-box-shadow:	@x @y @blur @spread rgba(@r, @v, @b, @alpha);
	-moz-box-shadow:	@x @y @blur @spread rgba(@r, @v, @b, @alpha);
	box-shadow:		@x @y @blur @spread rgba(@r, @v, @b, @alpha);
}
.opacity (@opacity) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
}
.text-shadow (@string: 0 1px 2px rgba(0, 0, 0, 0.6)) {
	text-shadow: @string;
}
.transition (@time) {
	-webkit-transition: all @time ease-in-out;
  	-moz-transition: all @time ease-in-out;
 	-o-transition: all @time ease-in-out;
  	transition: all @time ease-in-out;
}
.border-radius (@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

.calc(@prop; @val) {
  @{prop}: calc(~'@{val}');
  @{prop}: -moz-calc(~'@{val}');
  @{prop}: -webkit-calc(~'@{val}');
  @{prop}: -o-calc(~'@{val}');
}



/************************************************************
******** GENERAL
************************************************************/

::-moz-selection { color: @blanc;  background: @marron; }
::-o-selection { color: @blanc;  background: @marron; }
::-webkit-selection { color: @blanc;  background: @marron; }
::selection      { color: @blanc;  background: @marron; } 

body {
	font-family:@font-base;
	color:@texte-principal;
}

/*Titres, paragraphes, liens */
a, input, button {
	.transition(0.2s);
}
a {
    color:@marron;
    &:hover {
        color:@gris-tresfonce;
        text-decoration: none;
    }
}

h1,h2,h3,h4,h5,h6 {margin:0;padding:0;}

/* Boutons */
.btn {
	display: inline-block;
	padding: 14px 14px 11px 15px;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 3px;
	text-transform: uppercase;
	text-align: center;
	white-space: normal;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 0px solid transparent;
	.border-radius (0px)
}
.btn {
	// BTN par défaut
	&-default{
		color: @blanc !important;
  		background-color: @marron;
  		border-color: @marron;
		
		&:focus, &.focus {
			color: @blanc !important;
  			background-color: darken(@marron,10%);
  			border-color: darken(@marron,10%);
		}
		&:hover {
			color: @blanc !important;
  			background-color: darken(@marron,10%);
  			border-color: darken(@marron,10%);
		}
	}
	// BTN inverse par défaut
	&-inverse{
		color: @blanc !important;
  		background-color: @gris-fonce;
  		border-color: @gris-fonce;
		
		&:focus, &.focus {
			color: @blanc !important;
			background-color: @gris-tresfonce;
			border-color: @gris-tresfonce;
		}
		&:hover {
			color: @blanc !important;
			background-color: @gris-tresfonce;
			border-color: @gris-tresfonce;
		}
	}
}

/* Bloc texte informatif */
.blockDashed {
	text-align: center;
	margin: 10px;
	padding: 20px;
	border: 1px grey dashed;
	background: fade(@noir, 6%);
}

/* Colonnes hauteur égale */
.eqWrap {
    display: flex;
    flex-wrap: wrap;
}

/* Margin */
.margin-top5 {margin-top:5px !important;}
.margin-top10 {margin-top:10px !important;}
.margin-top15 {margin-top:15px !important;}
.margin-top20 {margin-top:20px !important;}
.margin-top25 {margin-top:25px !important;}
.margin-top30 {margin-top:30px !important;}
.margin-top35 {margin-top:35px !important;}
.margin-top40 {margin-top:40px !important;}
.margin-top45 {margin-top:45px !important;}
.margin-top50 {margin-top:50px !important;}
.margin-bottom5 {margin-bottom:5px !important;}
.margin-bottom10 {margin-bottom:10px !important;}
.margin-bottom15 {margin-bottom:15px !important;}
.margin-bottom20 {margin-bottom:20px !important;}
.margin-bottom25 {margin-bottom:25px !important;}
.margin-bottom30 {margin-bottom:30px !important;}
.margin-bottom35 {margin-bottom:35px !important;}
.margin-bottom40 {margin-bottom:40px !important;}
.margin-bottom45 {margin-bottom:45px !important;}
.margin-bottom50 {margin-bottom:50px !important;}

/* Visible/Hidden */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}

/** Vidéos responsive **/
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
.video-container iframe,  
.video-container object,  
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/** SFBadminbar **/
#sfbadminbar-body {
    margin-top: 0!important;
}

/** ReCaptcha V3 hidden **/
// .grecaptcha-badge{display: none;}

/* Small Devices, Tablets annule animations */
@media only screen and (max-width : 768px) {
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
}

/************************************************************
******** STYLE DES IMAGES
************************************************************/

.img {
	&-left {
		float:left;
		margin-right:10px;
		margin-bottom:10px;
		max-width:250px;
		height:auto !important;
	}
	&-right {
		float:right;
		margin-left:10px;
		margin-bottom:10px;
		max-width:250px;
		height:auto !important;
	}
	&-inline {
		display:inline-block;
		width:32% !important;
		margin:0.5%;
		height:auto !important;
	}
	&-responsive {
		display: block;
	  	max-width: 100%;
	  	margin:0 auto;
		height:auto !important;
	}
}

@media screen and (max-width: 768px) {
    .img {
		&-inline {
			display:block;
			width:100%;
			margin:0 auto;
			max-width:400px;
		}
    }
}

@media screen and (max-width: 450px) {
    .img {
		&-left {
			float:none;
			margin-right:0;
			margin-bottom:15px;
			max-width:100%;
		}
		&-right {
			float:none;
			margin-left:0;
			margin-bottom:15px;
			max-width:100%;
		}
    }
}

/***********************************************************
******* HEADER
***********************************************************/

#header {
	padding:15px 0 0 0;
	.vigneron-inde {
		font-size:12px;
		line-height:25px;
		text-transform: uppercase;
		letter-spacing: 1px;
		.icon-vigneron {
			height:25px;
			display: inline-block;
			img {height:100%;}
		}
	}
	.logo {
		max-width:100%;
		padding:20px 0 20px 0;
	}
	a {
		display:inline-block;
		color:@gris-fonce;
		font-size:12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		&:hover {
			i {color:@marron;}
		}
		i {.transition(0.2s);}
	}
	a.bag {
		span {
			font-size:10px;
			font-style:italic;
		}
	}
	a.bag + a.account {
		border-left:1px solid @gris-clair;
		margin-left:10px;
		padding-left:15px;
	}
	a.account + a.language {
		border-left:1px solid @gris-clair;
		margin-left:10px;
		padding-left:15px;
	}
}

@media screen and (max-width: 768px) {
	#header {
		.text-right {text-align:center !important;}
		.logo {padding-bottom:0;}
	}
}

/***********************************************************
******* MENU NAV BOOTSTRAP
***********************************************************/

.navbar-light {
	.navbar-brand {
		/* Par défaut caché, voir media queries plus bas */
		letter-spacing: 5px;
		text-transform: uppercase;
		color: @gris-fonce;
		font-weight: 700;
		font-size: 13px;
		line-height: 13px;
	}
	.navbar-nav {
		border-top:1px solid @gris-clair;
		.nav-item + .nav-item {padding-left:30px;}
		.nav-link {
			color: @gris-fonce;
			padding:10px 0;
			&:hover {
				color:@marron;
			}
		}
		.show>.nav-link {
			color:@marron;
		}
		.active {
			color:@gris-fonce;
			font-weight:700;
			
			&:hover .nav-link {
				color:@gris-fonce !important;
			}
			
		}
		.active>.nav-link {
			color:@gris-fonce;
		}
		.dropdown-menu {
			min-width: 100%;
			padding: 7px 0 0 0;
			margin: 0 0 0 15px;
			font-size: 15px;
			color: @gris-fonce;
			text-align: left;
			list-style: none;
			background-color: @blanc;
			background-clip: padding-box;
			border: 0px !important;
			border-top:1px solid @blanc !important;
			border-radius: 0;
			box-shadow:0;
			
			.dropdown-item {
				padding: 9px 15px 8px 15px;
				clear: both;
				font-weight: 400;
				color: @gris-fonce;
				text-align: inherit;
				white-space: nowrap;
				background-color: transparent;
				border: 0px !important;
				
				&:hover {
					background:@beige;
					color:@marron;
				}
			}
			.dropdown-item.active {
				color:@marron;
				font-weight:700;
				&:hover {
					background:transparent !important;
					color:@marron;
				}
			}
			.active {
				color:@gris-fonce;
				font-weight:700;

				&:hover .nav-link {
					color:@gris-fonce !important;
					background:transparent !important;
				}

			}
			.active>.nav-link {
				color:@gris-fonce;
			}
		}
	}
	.navbar-toggler {
		border-color: @gris-clair;
		margin-bottom:10px;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51,51,51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

/** Fonction faire disparaitre le logo de la navbar puis réapparaitre en mode burger **/
@media screen and (min-width: 767px) {
	.navbar-light {
		.navbar-brand {
			display:none;
		}
	}
	.dropdown:hover > .dropdown-menu {
		display: block;
	}
	.dropdown > .dropdown-toggle:active {
		pointer-events: none;
	}
	.dropdown-toggle::after {display:none;}
}

@media screen and (max-width: 991px) {
	.navbar-light {
		.navbar-nav {
			.nav-item + .nav-item {padding-left:20px;}
		}
	}
}
@media screen and (max-width: 767px) {
	.navbar-light {
		.navbar-nav {
			.nav-item + .nav-item {padding-left:0;}
		}
	}
}

/***********************************************************
******* CAROUSEL BOOTSTRAP
***********************************************************/

/** Carousel indicators & Carousel control **/
.carousel-indicators {
	bottom: 0;
	cursor:pointer;
}
.carousel-control.right,
.carousel-control.left {
	background-image: none;
}

/** Carousel items **/
.carousel {
	.carousel-item {
		min-height: 550px; 
		height: 100%;
		width:100%;
		background:url('../img/big_img_test.jpg')no-repeat center center;
		background-size:cover;
	}
}

/*** Fade effect ***/
// ajouter carousel-fade au même niveau que class="carousel slide" sur le front
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
} 
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
} 


/***********************************************************
******* FLASH INFOS
***********************************************************/

#flash-infos {
	background:@marron;
	color:@blanc;
	padding:20px 0;
	.badge {
		margin-right:15px;
		text-transform: uppercase;
		font-size: 12px;
		line-height:12px;
		letter-spacing: 1px;
		padding: 6px 12px 3px 12px;
	}
	.texte {
		margin-right:15px;
	}
	a.lire-plus {
		display: inline-block;
		padding: 6px 12px 3px 12px;
		border:1px solid @blanc;
		font-style: italic;
		font-size: 14px;
		line-height:14px;
		color:@blanc;
		&:hover {
			background:@blanc;
			color:@marron;
		}
	}
}

/***********************************************************
******* PRESENTATION HOME
***********************************************************/

#presentation-home {
	padding:100px 0;
	.presentation {
		padding-bottom:100px;
		h1 {
			font-size:28px;
			line-height:38px;
			margin-bottom:35px;
		}
		a.lire-plus {
			letter-spacing: 5px;
			text-transform: uppercase;
			color:@marron;
			font-weight:700;
			font-size:13px;
			line-height:13px;
			padding:4px 0 1px 4px;
			&:hover {
				background:@marron;
				color:@blanc;
			}
		}
	}
	.all-bloc {
		.textA {
			padding:0 40px 40px 40px;
			h2.h2textA {
				font-size:25px;
				font-style:italic;
				color:@marron;
				margin-bottom:25px;
				span {
					display:block;
					color:@gris-tresfonce;
					font-size:48px;
					font-style: normal;
				}
			}
			p {
				font-size:17px;
				line-height:28px;
			}
			a.lire-plus {
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				&:after {
					display: inline-block;
					content:'';
					width:35px;
					height:1px;
					background:@marron;
					margin-bottom:5px;
					margin-left:15px;
					.transition(0.2s);
				}
				&:hover {
					color:darken(@marron,10%);
					&:after {
						margin-left:5px;
						background:@gris-clair;
					}
				}
			}
		}
		.textB {
			padding:40px 40px 0 40px;
			h2.h2textB {
				font-size:25px;
				font-style:italic;
				color:@marron;
				margin-bottom:25px;
				span {
					display:block;
					color:@gris-tresfonce;
					font-size:48px;
					font-style: normal;
				}
			}
			p {
				font-size:17px;
				line-height:28px;
			}
			a.lire-plus {
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				&:after {
					display: inline-block;
					content:'';
					width:35px;
					height:1px;
					background:@marron;
					margin-bottom:5px;
					margin-left:15px;
					.transition(0.2s);
				}
				&:hover {
					color:darken(@marron,10%);
					&:after {
						margin-left:5px;
						background:@gris-clair;
					}
				}
			}
		}
		.imgIntro {
			width:100%;
			height:750px;
			background:url('../img/big_img_test.jpg')no-repeat center center;
			background-size:cover;
		}
	}
}

@media screen and (max-width: 992px) {
	#presentation-home {
		.all-bloc {
			.textA {padding:0 0 40px 0;}
			.textB {padding:40px 0 0 0;}
			.imgIntro {height:650px;}
		}
	}
}
@media screen and (max-width: 768px) {
	#presentation-home {
		.all-bloc {
			display: flex;
			flex-wrap: wrap;
			.textB {
				order:1;
				padding:40px 0 40px 0;
			}
			.imgIntro {order:2;}
		}
	}
}
@media screen and (max-width: 450px) {
	#presentation-home {
		.all-bloc {
			.imgIntro {height:550px;}
		}
	}
}
@media screen and (max-width: 350px) {
	#presentation-home {
		padding:60px 0;
		.presentation {
			padding-bottom:60px; 
			h1 {
				font-size:24px;
				line-height:32px;
				margin-bottom:30px;
			}
		}
		.all-bloc {
			.textA {
				h2.h2textA {
					font-size:22px;
					margin-bottom:20px;
					span {
						font-size:38px;
						line-height:40px;
					}
				}
			}
			.textB {
				h2.h2textB {
					font-size:22px;
					margin-bottom:20px;
					span {
						font-size:38px;
						line-height:40px;
					}
				}
			}
			.imgIntro {height:450px;}
		}
	}
}


/***********************************************************
******* PARALLAX HOME
***********************************************************/

.parallax-home {
	height:450px;
	text-align: center;
	background:url(../img/big_img_test.jpg) no-repeat center center;
	background-size:cover;
	position:relative;
	.parallax-black {
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: fade(@noir, 50%);
		background: fade(@noir, 50%);
		color: fade(@noir, 50%);
		position: absolute;
		top: 0;
		height: 100%;
	}
}

/** Correctif fixed sur version mobile **/
@media only screen and (min-width: 992px) {
	.parallax-home {
    	background-attachment: fixed;
  	}
}

#parallax-actus {
	position: relative;
	margin-top:-200px;
	margin-bottom:80px;
	.parallax-content {
		background:@beige;
		padding:80px 60px 70px 60px;
		text-align:center;
		h2.title-newsG {
			letter-spacing: 5px;
			text-transform: uppercase;
			color:@gris-clair;
			font-weight:700;
			font-size:13px;
			margin-bottom:35px;
		}
		#owl-actus {
			.item {
				margin-bottom:35px;
				h3.title-news {
					color:@marron;
					font-size:35px;
					line-height:37px;
					margin-bottom:30px;
				}
				.content-news {
					padding-bottom:20px;
					p {
						font-size:17px;
						line-height:28px;
						margin-bottom:0;
					}
				}
				a.lire-plus {
					letter-spacing: 5px;
					text-transform: uppercase;
					color:@marron;
					font-weight:700;
					font-size:13px;
					line-height:13px;
					padding:4px 0 1px 4px;
					&:hover {
						background:@marron;
						color:@blanc;
					}
				}
			}
		}
		#owl-actus.owl-theme .owl-nav.disabled + .owl-dots {margin-top: 0;}
		#owl-actus.owl-theme .owl-dots .owl-dot span {
			margin: 0 3px;
			background:@gris-clair;
		}
		#owl-actus.owl-theme .owl-dots .owl-dot.active span {background:@marron;}
		#owl-actus.owl-theme .owl-dots .owl-dot.active:hover span {background:@marron;}
		#owl-actus.owl-theme .owl-dots .owl-dot:hover span {background:@gris-fonce;}
		#owl-actus.owl-theme .owl-dots .owl-dot:focus {outline: none;}
	}
}

@media screen and (max-width: 992px) {
	.parallax-home {height:350px;}
	#parallax-actus {
		.parallax-content {padding:60px 40px 50px 40px;}
	}
}
@media screen and (max-width: 768px) {
	#parallax-actus {
		.parallax-content {
			padding:50px 20px 30px 20px;
			#owl-actus {
				.item {
					margin-bottom:30px;
					h3.title-news {
						font-size:28px;
						line-height:30px;
						margin-bottom:15px;
					}
					.content-news {padding-bottom:15px;}
				}
			}
		}
	}
}


/***********************************************************
******* HAUT PAGE
***********************************************************/

.haut-page-bg {
	height:450px;
	text-align: center;
	background:url(../img/big_img_test.jpg) no-repeat center center;
	background-size:cover;
	position:relative;
	.parallax-black {
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: fade(@noir, 50%);
		background: fade(@noir, 50%);
		color: fade(@noir, 50%);
		position: absolute;
		top: 0;
		height: 100%;
	}
}

/** Correctif fixed sur version mobile **/
@media only screen and (min-width: 992px) {
	.haut-page-bg {
    	background-attachment: fixed;
  	}
}

#haut-page {
	position: relative;
	margin-top:-200px;
	margin-bottom:80px;
	.parallax-content {
		background:@beige;
		padding:80px 60px 70px 60px;
		text-align:center;
		h1.title-main {
			color:@gris-tresfonce;
			font-size:40px;
			line-height:46px;
			span {
				display:block;
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				margin-bottom:35px;
			}
		}
		.subtitle {
			margin-top:35px;
			p {
				font-size: 18px;
    			line-height: 29px;
				margin-bottom:0;
			}
			span.horaires {
				text-transform: lowercase;
			}
		}
	}
}

.haut-page-bg-tunnel {height:210px;}
#haut-page.haut-page-tunnel {
	margin-top:-150px;
	margin-bottom:20px;
	.parallax-content {
		h1.title-main {
			span {display:none;}
		}
		.subtitle {margin-top:10px;}
	}
}

@media screen and (max-width: 992px) {
	.haut-page-bg {height:350px;}
	#haut-page {
		.parallax-content {
			padding:60px 40px 50px 40px;
			h1.title-main {
				font-size:35px;
				line-height:38px;
				span {margin-bottom:20px;}
			}
			.subtitle {
				margin-top:20px;
				p {
					font-size: 17px;
					line-height: 25px;
				}
			}
		}
	}
	
	.haut-page-bg-tunnel {height:210px;}
}
@media screen and (max-width: 768px) {
	#haut-page {
		.parallax-content {
			padding:50px 30px 50px 30px;
			h1.title-main {
				font-size:32px;
				line-height:34px;
			}
		}
	}
}
@media screen and (max-width: 575px) {
	.haut-page-bg {height:215px;}
	#haut-page {margin-bottom:60px;}
	
	.haut-page-bg-tunnel {height:165px;}
}

/***********************************************************
******* INT
***********************************************************/

#int {
	padding:20px 0 100px 0;
	h2 {
		font-size:32px;
		line-height:36px;
		color:@marron;
		margin-bottom:6px;
	}
	
	h3 {
		font-size:26px;
		line-height:30px;
		color:@gris-tresfonce;
		margin-bottom:6px;
	}
	.subtitle {
		font-size: 1.3em;
	}
	p + h2 {margin-top:30px;}
	h2 + h3 {margin-top:15px;}
	.infos-commande {
		margin-bottom:40px;
		text-align:center;
		a.js-scrollTo {
			color:@marron;
			cursor: pointer;
			font-weight:700;
			&:hover {
				color:@gris-tresfonce;
			}
		}
		a.btn-commander {
			display: inline-block;
			letter-spacing: 3px;
			text-transform: uppercase;
			background: @marron;
			color:@blanc;
			font-weight: 700;
			font-size: 12px;
			line-height:12px;
			padding:12px 8px 8px 10px;
			&:hover {
				background:@gris-fonce;
			}
		}
	}
	.actu {
		text-align:center;
		p {margin:0;}
	}
	.actu + .actu {
		margin-top:30px;
		padding-top:30px;
		border-top:1px solid @beige;
	}
	.row-photo-left,
	.row-photo-right {
		.colTexte {
			padding:50px 15px;
			p {
				text-align:justify;
				margin-bottom:0;
			}
		}
		.colImage {
			background:url('../img/big_img_test.jpg')no-repeat center center;
			background-size:cover;
		}
	}
	.row-photo-left {
		.colImage {border-left:15px solid @blanc;}
		.colTexte {padding-left:50px;}
	}
	.row-photo-right {
		.colImage {border-right:15px solid @blanc;}
		.colTexte {padding-right:50px;}
	}
    .box-content {
        width:250px;
        margin:0 auto;
		&:after {
			display: block;
			clear: both;
			content: "";
		}
		dt {
			float:left;
			clear:left;
			margin:0;
		}
		dd {
			float:right;
			clear:right;
			margin:0;
		}
	}
}

@media screen and (max-width: 767px) {
	#int {
		.row-photo-left,
		.row-photo-right {
			.colTexte {
				padding:0 15px 15px 15px !important;
				p {text-align:left;}
			}
			.colImage {
				border-left:15px solid @blanc;
				border-right:15px solid @blanc;
				height:400px;
				margin-bottom:30px;
			}
		}
		.row-photo-left {
			.order-1 {order:2;}
			.order-2 {order:1;}
		}
	}
}
@media screen and (max-width: 575px) {
	#int {
		padding:0 0 60px 0;
		.row-photo-left,
		.row-photo-right {
			.colImage {height:280px;}
		}
	}
}

/***********************************************************
******* SIDEBAR
***********************************************************/

#sidebar {
	position: sticky;
	top:30px;
	text-align:center;
	.title-sidebar {
		font-size:25px;
		line-height:25px;
		margin-bottom:15px;
		color:@gris-fonce;
	}
	a {
		color:@marron;
		font-weight:700;
		&:hover {
			color:@gris-tresfonce;
		}
	}
	a.panier {
		display: block;
		letter-spacing: 3px;
		text-transform: uppercase;
		color: @marron;
		font-weight: 700;
		font-size: 12px;
		margin-bottom: 8px;
		&:hover {
			color:@gris-tresfonce;
		}
	}
	a.account,
	a.logout {
		display:inline-block;
		color: @marron;
		font-weight: 700;
		font-size:15px;
		&:hover {
			color:@gris-tresfonce;
		}
	}
	a.btn-commander {
		display: inline-block;
		letter-spacing: 3px;
		text-transform: uppercase;
		background: @marron;
		color:@blanc;
		font-weight: 700;
		font-size: 12px;
		line-height:12px;
		margin-top: 8px;
		padding:12px 8px 8px 10px;
		&:hover {
			background:@gris-fonce;
		}
	}
	hr {border-color:@beige;}
	.box-content {
		width:auto;
        margin:inherit;
	}
}

@media screen and (max-width: 992px) {
	#sidebar {padding-top:40px;}
}

/***********************************************************
******* .WINE
***********************************************************/

.wine {
	border:1px solid @beige;
	padding:30px;
	.content-main {
		display:flex;
		flex-wrap: nowrap;
		.photo {
			position: relative;
			width:250px;
			height:max-content;
			text-align: center;
			.photo-wine {
				position: relative;
				z-index:2;
				max-width:130px;
				margin:0 auto;
			}
			.rond {
				position:absolute;
				z-index:1;
				top:50%;
				left:50%;
				margin-top:-125px;
				margin-left:-125px;
				width:250px;
				height:250px;
				border-radius: 250px;
				background:url('../img/bg-wine.png')repeat;
			}
		}
		.text {
			width: ~"calc(100% - 250px)";
			padding-left:30px;
			h2.title-wine {
				font-size:32px;
				line-height:34px;
				margin-bottom:20px;
				color:@gris-tresfonce;
				span.year {
					display: inline-block;
					background:@marron;
					color:@blanc;
					font-size:18px;
					line-height:18px;
					padding:4px 6px 2px 6px;
				}
			}
			.resume {
				margin-bottom:15px;
				p {margin-bottom:8px;}
			}
			.voir-plus {
				display: block;
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				margin-bottom:20px;
				&:before {
					content: attr(data-vplabel);
				}
				&:after {
					display: inline-block;
					content:'';
					width:35px;
					height:1px;
					background:@marron;
					margin-bottom:5px;
					margin-left:15px;
					.transition(0.2s);
				}
				&:hover {
					color:darken(@marron,10%);
					&:after {
						margin-left:5px;
						background:@gris-clair;
					}
				}
			}
			.voir-moins {
				display: block;
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				margin-bottom:20px;
				&:before {
					content: attr(data-vmlabel);
				}
				&:after {
					display: inline-block;
					content:'';
					width:35px;
					height:1px;
					background:@marron;
					margin-bottom:5px;
					margin-left:15px;
					.transition(0.2s);
				}
				&:hover {
					color:darken(@marron,10%);
					&:after {
						margin-left:5px;
						background:@gris-clair;
					}
				}
			}
			.awards {
				background: @beige;
				padding:15px 15px 12px 15px;
				margin-bottom:20px;
				.title-awards {
					letter-spacing: 3px;
					text-transform: uppercase;
					font-weight: 700;
					font-size: 13px;
					line-height:13px;
					margin-bottom:8px;
					text-decoration:underline;
				}
				p {
					font-size:15px;
					line-height:18px;
					margin-bottom:0;
				}
			}
			.btn-panier {
				display:inline-block;
				background:@marron;
				border:0;
				letter-spacing: 3px;
				text-transform: uppercase;
				color:@blanc;
				font-weight:700;
				font-size:15px;
				line-height:42px;
				height:40px;
				width:260px;
				text-align:center;
				margin-top:20px;
				-webkit-appearance: inherit;
				&:hover {
					background:@gris-fonce;
				}
			}
			.others-btn {
				position:relative;
				.photo-plus,
				.sheet {
					display:block;
					position: absolute;
					top:-40px;
					height:40px;
					width:40px;
					background:fade(@marron,60%);
					color:@blanc;
					text-align:center;
					line-height:45px;
					font-size:16px;
					cursor: pointer;
					&:hover {
						background:@gris-fonce;
					}
				}
				.photo-plus {
					left:264px;
					span {
						display:none;
						letter-spacing: 3px;
						text-transform: uppercase;
						color: #fff;
						font-weight: 700;
						font-size: 12px;
						margin-left:5px;
					}
					.transition(0.2s);
					a.fancybox {
						position: absolute;
						top:0;
						left:0;
						right:0;
						bottom:0;
					}
					.hidden-all {display:none;}
				}
				.sheet {left:308px;}
			}
            .others-btn-NOSTOCK {
                .photo-plus,
                .sheet {
                    position: relative !important;
                    display:inline-block !important;
                    top:inherit !important;
                    left: inherit !important;
                }
            }
			.description-plus {
				margin-top:15px;
				p {margin-bottom:0;}
				p + p {margin-top:8px;}
				h3 {
					font-size:19px;
					line-height:21px;
					font-weight:700;
					margin-bottom:5px;
				}
				p + h3 {
					margin-top:15px;
				}
			}
		}
	}
}
.wine + .wine {margin-top:30px;}

@media screen and (max-width: 767px) {
	.wine {
		padding:30px 20px;
		.content-main {
			.photo {
				width:150px;
				.photo-wine {max-width:100px;}
				.rond {
					margin-top:-75px;
					margin-left:-75px;
					width:150px;
					height:150px;
					border-radius: 150px;
				}
			}
			.text {
				width: ~"calc(100% - 150px)";
				padding-left: 15px;
				h2.title-wine {
					font-size:28px;
					line-height:30px;
				}
				.others-btn {
					.photo-plus,
					.sheet {
						display:inline-block;
						position: relative;
						top:inherit;
						margin-top:4px;
					}
					.photo-plus {left:inherit;}
					.sheet {left:inherit;}
				}
			}
		}
	}
}
@media screen and (max-width: 500px) {
	.wine {
		padding:20px;
		.content-main {
			flex-wrap: wrap;
			.photo {display:none;}
			.text {
				width: 100%;
				padding-left: 0;
				h2.title-wine {
					font-size:23px;
					line-height:25px;
					span.year {
						font-size:14px;
						line-height:14px;
					}
				}
				.voir-plus {
					&:after {display: none;}
				}
				.voir-moins {
					&:after {display: none;}
				}
				.btn-panier {
					font-size:14px;
					width:220px;
				}
				.others-btn {
					.photo-plus {
						width:auto;
						padding:0 8px;
						span {display:inline-block;}
					}
				}
			}
		}
	}
}

/***********************************************************
******* MODAL PANIER
***********************************************************/

#ModalPanier {
	.modal-header {
		.modal-title {
			letter-spacing: 3px;
			text-transform: uppercase;
			color: @marron;
			font-weight: 700;
			font-size: 13px;
			line-height:13px;
			padding-top:8px;
		}
	}
	.modal-body {
		.name-add {
			font-size:28px;
			line-height:30px;
			color:@gris-tresfonce;
			margin-top:10px;
			span {
				display: inline-block;
				background:@marron;
				color:@blanc;
				font-size:18px;
				line-height:18px;
				padding:4px 6px 2px 6px;
			}
		}
	}
	.modal-footer {
		justify-content:flex-start;
	}
}

@media screen and (max-width: 450px) {
	#ModalPanier {
		.modal-footer {
			flex-wrap: wrap;
			.btn {
				display:block;
				width:100% !important;
			}
		}
		.modal-footer>:not(:last-child) {margin-right: 0 !important;}
		.modal-footer>:not(:first-child) {margin-left: 0 !important;}
		.btn + .btn {margin-top:4px;}
	}
}

/***********************************************************
******* TUNNEL COMPTE
***********************************************************/

#tunnel-compte {
	.table {
		margin:0;
		thead {
			background:@gris-tresclair;
		}
		th {
			vertical-align:middle;
			padding:10px 10px 6px 10px;
		}
		td {vertical-align:middle;}
	}
	.table-total {
		background:@gris-tresclair;
		th, td {
			vertical-align:middle;
			padding:10px 10px 6px 10px;
		}
		td {width:280px;}
		.btn-link {
			padding:0;
			margin:0 5px;
			border:0;
			background:transparent;
			outline:none;
			color:fade(@marron,50%);
			&:hover,
			&:focus {color:@marron;}
		}
		
	}
	.form-group.row {margin:0 0 15px 0;}
	.btn-copie-infos {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 13px;
		line-height:13px;
		margin-bottom: 35px;
		border:1px solid @gris-fonce;
		padding:3px 0 0 6px;
		cursor: pointer;
		&:hover {
			border-color:@marron;
			color:@marron;
		}
	}
	h2.confirmTitle {
		font-size:25px;
		line-height:27px;
	}
}
.popover {min-width:180px !important;}

@media screen and (max-width: 991px) {
	#tunnel-compte {
		.table-total {
			td {width:auto;}
		}
	}
}
@media screen and (max-width: 767px) {
	.marginTopRegister {margin-top:30px;}
}
@media screen and (max-width: 575px) {
	#tunnel-compte {
		.row-btn {
			.text-left {text-align:right !important;}
		}
		.form-group.row {margin:0 -15px 15px -15px;}
	}
}

/***********************************************************
******* OFFRES
***********************************************************/

#offres {
    .all-offre {
        .text {
			h2.h2text {
				font-size:25px;
				font-style:italic;
				color:@marron;
				margin-bottom:25px;
				span {
					display:block;
					color:@gris-tresfonce;
					font-size:48px;
					font-style: normal;
				}
			}
			.time-price {
				display: inline-block;
				background:@marron;
				color:@blanc;
				font-size:16px;
				line-height:16px;
				padding:5px 4px 3px 4px;
			}
			p, ul {
				font-size:17px;
				line-height:28px;
				margin-bottom:0;
			}
			p + ul {margin-top:0;}
			a.lire-plus {
				display:block;
				letter-spacing: 5px;
				text-transform: uppercase;
				color:@marron;
				font-weight:700;
				font-size:13px;
				margin-top:20px;
				&:after {
					display: inline-block;
					content:'';
					width:35px;
					height:1px;
					background:@marron;
					margin-bottom:5px;
					margin-left:15px;
					.transition(0.2s);
				}
				&:hover {
					color:darken(@marron,10%);
					&:after {
						margin-left:5px;
						background:@gris-clair;
					}
				}
			}
		}  
        .imgintro {
            background:url('../img/big_img_test.jpg')no-repeat center center;
			background-size:cover;
            border-left:15px solid @blanc;
            border-right:15px solid @blanc;
            @media screen and (max-width: 768px) {
                height:250px;
                margin-top:15px;
            }
        }
    }
    .all-offre + .all-offre {
        margin-top:50px;
    }
}

/***********************************************************
******* CARD COLUMNS
***********************************************************/

.card-columns {
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
	-webkit-column-gap: 0;
	-moz-column-gap: 0;
	column-gap: 0;
	orphans: 1;
	widows: 1;
	
	.card {
		border-radius:0;
		border:0;
		margin-bottom:20px;
		background:@beige;
		
		&:hover img{
			opacity:.7;
		}
		
		img {
			width:100%;
			.transition(0.2s);
		}
		a {
			display: block;
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}

@media screen and (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    orphans: 1;
    widows: 1;
  }
}
@media screen and (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    orphans: 1;
    widows: 1;
  }
}

/***********************************************************
******* FORMULAIRES (avec Parsley)
***********************************************************/


.coordonnees-info {
	position:relative;
	text-align:center;
	min-height:250px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	.content {
		position:relative;
		z-index:2;
		height:100%;
		.icon {
			color:@gris-fonce;
			font-size:30px;
			margin-bottom:10px;
			height:30px;
			.transition(0.2s);
		}
		h2.info {
			display: block;
			letter-spacing: 5px;
			text-transform: uppercase;
			color: @marron !important;
			font-weight: 700;
			font-size: 18px !important;
			line-height: 18px !important;
			margin-bottom: 10px !important;
			.transition(0.2s);
		}
		.text {
			color:@gris-fonce;
			.transition(0.2s);
			i {font-size:11px;}
		}
	}
	.bg-round {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		margin-top: -125px;
		margin-left: -125px;
		width: 250px;
		height: 250px;
		border-radius: 250px;
		background: url(../img/bg-wine.png) repeat;
	}
}
a.coordonnees-info:hover {
	.content {
		.icon {color:@marron;}
		h2.info {color:@gris-fonce !important;}
		.text {color:@marron;}
	}
}

@media screen and (max-width: 991px) {
	.coordonnees-info {
		min-height:210px;
		.bg-round {
			margin-top: -105px;
			margin-left: -105px;
			width: 210px;
			height: 210px;
			border-radius: 210px;
		}
	}
}
@media screen and (max-width: 767px) {
	.coordonnees-info {
		min-height:inherit;
		.margin-top25 {margin-top:10px !important;}
		.bg-round {display:none;}
	}
}

.margin-topFORM50 {margin-top:50px;}
@media screen and (max-width: 575px) {
	.margin-topFORM50 {margin-top:0;}
}

.gmap {
	height:100%;
	iframe {height:100%;}
}

label {
	margin:0;
}

.col-form-label {text-align:right;}
@media screen and (max-width: 575px) {
	.col-form-label {
		text-align:left;
		padding:0 15px !important;
	}
}

textarea.form-control {height: auto;}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: @texte-principal;
  background-color: @blanc;
  background-image: none;
  border: 1px solid @gris-clair;
  .border-radius (4px);
  -webkit-box-shadow: inset 0 1px 1px fade(@noir, 7.5%);
          box-shadow: inset 0 1px 1px fade(@noir, 7.5%);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  
	&:focus {
		border-color: @marron;
  		outline: 0;
  		-webkit-box-shadow: inset 0 1px 1px fade(@noir, 7.5%), 0 0 8px fade(@marron, 0%);
        	box-shadow: inset 0 1px 1px fade(@noir, 7.5%), 0 0 8px fade(@marron, 0%);
	}
	
	&::-moz-placeholder {
		color: @gris-moyenclair;
  		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: @gris-moyenclair;
	}
	&::-webkit-input-placeholder {
		color: @gris-moyenclair;
	}
}

.parsley {
	&-success{
		border:solid 1px @gris-clair !important;
		background: @blanc !important;
		color: @gris-fonce !important;
	}
	&-error {
		border:solid 1px @rouge !important;
		background:fade(@rouge, 20%) !important;
		color:@noir !important;
	}
	&-errors-list {
		color:@rouge;
		list-style:none;
		padding:0;
	}
}

.red {color:@marron;list-style:none;padding:0;}

#form_contact {
	.required {
		color:@gris-fonce;list-style:none;padding:0;
	}
	.success {
		color:@texte-principal;
	}
	
	label.required:after {
		content: " *";
		color: @marron;
		position: absolute;
		margin: 0 0 0 2px;
		font-size: 14px;
		padding: 0 5px 0 0;
	}
}

.ui-datepicker .ui-datepicker-title select {
	color: @gris-fonce;
}

.parsley-error {
	&::-webkit-input-placeholder{
		color: @gris-fonce;
	}
	&:-moz-placeholder{
		color: @gris-fonce;
	}
	&::-moz-placeholder{
		color: @gris-fonce;
	}
	&:-ms-input-placeholder{
		color: @gris-fonce;
	}
}

/***********************************************************
******* FOOTER
***********************************************************/

#footer {
	padding-top:15px;
	a {
		color:@gris-fonce;
		&:hover {color:@marron;}
	}
    ul {
        padding:0;
        margin:0;
        list-style: none;
        li {
            a.nav-link {
                padding:0;
            }
        }
    }
	.footer-up {
		.logo-footer {
			max-width:100%;
			margin:0 auto;
			padding-bottom:55px;
		}
	}
	.footer-middle {
		.title-footer {
			letter-spacing: 2px;
			text-transform: uppercase;
			color: @marron;
			font-weight: 700;
			font-size: 15px;
			margin-bottom: 15px;
		}
        .content {
            p {margin-bottom:0;}
        }
		a {
			i {font-size:11px;}
		}
	}
	.footer-down {
		padding-top:60px;
		i {
			font-size:20px;
			margin-top:5px;
		}
		.img-vigneron {
			display: block;
			max-width:100%;
			margin:0 auto;
			padding-bottom:15px;
		}
	}
	.bg-footer {
		background:url('../img/bg-footer.jpg')no-repeat top center;
		background-size:cover;
		width:100%;
		height:280px;
	}
}

@media screen and (max-width: 768px) {
	#footer {
		.footer-up {
			.logo-footer {
				max-width:100px;
				padding-bottom:0;
			}
		}
		.footer-middle {
			.col-12 {padding-top:30px;}
			.title-footer {
				margin-top:30px;
				margin-bottom:10px;
			}
		}
		.bg-footer {height:200px;}
	}
}
@media screen and (max-width: 576px) {
	#footer {
		.footer-middle {
			.col-12 {padding-top:0;}
		}
		.bg-footer {height:120px;}
	}
}

.help-block {
	color: @error;
	font-size: 0.9em;
}