/************************************************************
******** GENERAL
************************************************************/
::-moz-selection {
  color: #fff;
  background: #8C4653;
}
::-o-selection {
  color: #fff;
  background: #8C4653;
}
::-webkit-selection {
  color: #fff;
  background: #8C4653;
}
::selection {
  color: #fff;
  background: #8C4653;
}
body {
  font-family: 'Old Standard TT', serif;
  color: #333;
}
/*Titres, paragraphes, liens */
a,
input,
button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a {
  color: #8C4653;
}
a:hover {
  color: #1a1a18;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* Boutons */
.btn {
  display: inline-block;
  padding: 14px 14px 11px 15px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
  white-space: normal;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0px solid transparent;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.btn-default {
  color: #fff !important;
  background-color: #8C4653;
  border-color: #8C4653;
}
.btn-default:focus,
.btn-default.focus {
  color: #fff !important;
  background-color: #6a353f;
  border-color: #6a353f;
}
.btn-default:hover {
  color: #fff !important;
  background-color: #6a353f;
  border-color: #6a353f;
}
.btn-inverse {
  color: #fff !important;
  background-color: #333;
  border-color: #333;
}
.btn-inverse:focus,
.btn-inverse.focus {
  color: #fff !important;
  background-color: #1a1a18;
  border-color: #1a1a18;
}
.btn-inverse:hover {
  color: #fff !important;
  background-color: #1a1a18;
  border-color: #1a1a18;
}
/* Bloc texte informatif */
.blockDashed {
  text-align: center;
  margin: 10px;
  padding: 20px;
  border: 1px grey dashed;
  background: rgba(0, 0, 0, 0.06);
}
/* Colonnes hauteur égale */
.eqWrap {
  display: flex;
  flex-wrap: wrap;
}
/* Margin */
.margin-top5 {
  margin-top: 5px !important;
}
.margin-top10 {
  margin-top: 10px !important;
}
.margin-top15 {
  margin-top: 15px !important;
}
.margin-top20 {
  margin-top: 20px !important;
}
.margin-top25 {
  margin-top: 25px !important;
}
.margin-top30 {
  margin-top: 30px !important;
}
.margin-top35 {
  margin-top: 35px !important;
}
.margin-top40 {
  margin-top: 40px !important;
}
.margin-top45 {
  margin-top: 45px !important;
}
.margin-top50 {
  margin-top: 50px !important;
}
.margin-bottom5 {
  margin-bottom: 5px !important;
}
.margin-bottom10 {
  margin-bottom: 10px !important;
}
.margin-bottom15 {
  margin-bottom: 15px !important;
}
.margin-bottom20 {
  margin-bottom: 20px !important;
}
.margin-bottom25 {
  margin-bottom: 25px !important;
}
.margin-bottom30 {
  margin-bottom: 30px !important;
}
.margin-bottom35 {
  margin-bottom: 35px !important;
}
.margin-bottom40 {
  margin-bottom: 40px !important;
}
.margin-bottom45 {
  margin-bottom: 45px !important;
}
.margin-bottom50 {
  margin-bottom: 50px !important;
}
/* Visible/Hidden */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}
@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
}
/** Vidéos responsive **/
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/** SFBadminbar **/
#sfbadminbar-body {
  margin-top: 0!important;
}
/** ReCaptcha V3 hidden **/
/* Small Devices, Tablets annule animations */
@media only screen and (max-width: 768px) {
  .animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
/************************************************************
******** STYLE DES IMAGES
************************************************************/
.img-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 250px;
  height: auto !important;
}
.img-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
  max-width: 250px;
  height: auto !important;
}
.img-inline {
  display: inline-block;
  width: 32% !important;
  margin: 0.5%;
  height: auto !important;
}
.img-responsive {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  height: auto !important;
}
@media screen and (max-width: 768px) {
  .img-inline {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .img-left {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .img-right {
    float: none;
    margin-left: 0;
    margin-bottom: 15px;
    max-width: 100%;
  }
}
/***********************************************************
******* HEADER
***********************************************************/
#header {
  padding: 15px 0 0 0;
}
#header .vigneron-inde {
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#header .vigneron-inde .icon-vigneron {
  height: 25px;
  display: inline-block;
}
#header .vigneron-inde .icon-vigneron img {
  height: 100%;
}
#header .logo {
  max-width: 100%;
  padding: 20px 0 20px 0;
}
#header a {
  display: inline-block;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#header a:hover i {
  color: #8C4653;
}
#header a i {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#header a.bag span {
  font-size: 10px;
  font-style: italic;
}
#header a.bag + a.account {
  border-left: 1px solid #ccc;
  margin-left: 10px;
  padding-left: 15px;
}
#header a.account + a.language {
  border-left: 1px solid #ccc;
  margin-left: 10px;
  padding-left: 15px;
}
@media screen and (max-width: 768px) {
  #header .text-right {
    text-align: center !important;
  }
  #header .logo {
    padding-bottom: 0;
  }
}
/***********************************************************
******* MENU NAV BOOTSTRAP
***********************************************************/
.navbar-light .navbar-brand {
  /* Par défaut caché, voir media queries plus bas */
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #333;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
}
.navbar-light .navbar-nav {
  border-top: 1px solid #ccc;
}
.navbar-light .navbar-nav .nav-item + .nav-item {
  padding-left: 30px;
}
.navbar-light .navbar-nav .nav-link {
  color: #333;
  padding: 10px 0;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #8C4653;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #8C4653;
}
.navbar-light .navbar-nav .active {
  color: #333;
  font-weight: 700;
}
.navbar-light .navbar-nav .active:hover .nav-link {
  color: #333 !important;
}
.navbar-light .navbar-nav .active > .nav-link {
  color: #333;
}
.navbar-light .navbar-nav .dropdown-menu {
  min-width: 100%;
  padding: 7px 0 0 0;
  margin: 0 0 0 15px;
  font-size: 15px;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px !important;
  border-top: 1px solid #fff !important;
  border-radius: 0;
  box-shadow: 0;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item {
  padding: 9px 15px 8px 15px;
  clear: both;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0px !important;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item:hover {
  background: #faf6ed;
  color: #8C4653;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item.active {
  color: #8C4653;
  font-weight: 700;
}
.navbar-light .navbar-nav .dropdown-menu .dropdown-item.active:hover {
  background: transparent !important;
  color: #8C4653;
}
.navbar-light .navbar-nav .dropdown-menu .active {
  color: #333;
  font-weight: 700;
}
.navbar-light .navbar-nav .dropdown-menu .active:hover .nav-link {
  color: #333 !important;
  background: transparent !important;
}
.navbar-light .navbar-nav .dropdown-menu .active > .nav-link {
  color: #333;
}
.navbar-light .navbar-toggler {
  border-color: #ccc;
  margin-bottom: 10px;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51,51,51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
/** Fonction faire disparaitre le logo de la navbar puis réapparaitre en mode burger **/
@media screen and (min-width: 767px) {
  .navbar-light .navbar-brand {
    display: none;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .dropdown > .dropdown-toggle:active {
    pointer-events: none;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .navbar-light .navbar-nav .nav-item + .nav-item {
    padding-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .navbar-light .navbar-nav .nav-item + .nav-item {
    padding-left: 0;
  }
}
/***********************************************************
******* CAROUSEL BOOTSTRAP
***********************************************************/
/** Carousel indicators & Carousel control **/
.carousel-indicators {
  bottom: 0;
  cursor: pointer;
}
.carousel-control.right,
.carousel-control.left {
  background-image: none;
}
/** Carousel items **/
.carousel .carousel-item {
  min-height: 550px;
  height: 100%;
  width: 100%;
  background: url('../img/big_img_test.jpg') no-repeat center center;
  background-size: cover;
}
/*** Fade effect ***/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/***********************************************************
******* FLASH INFOS
***********************************************************/
#flash-infos {
  background: #8C4653;
  color: #fff;
  padding: 20px 0;
}
#flash-infos .badge {
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  padding: 6px 12px 3px 12px;
}
#flash-infos .texte {
  margin-right: 15px;
}
#flash-infos a.lire-plus {
  display: inline-block;
  padding: 6px 12px 3px 12px;
  border: 1px solid #fff;
  font-style: italic;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
}
#flash-infos a.lire-plus:hover {
  background: #fff;
  color: #8C4653;
}
/***********************************************************
******* PRESENTATION HOME
***********************************************************/
#presentation-home {
  padding: 100px 0;
}
#presentation-home .presentation {
  padding-bottom: 100px;
}
#presentation-home .presentation h1 {
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 35px;
}
#presentation-home .presentation a.lire-plus {
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  padding: 4px 0 1px 4px;
}
#presentation-home .presentation a.lire-plus:hover {
  background: #8C4653;
  color: #fff;
}
#presentation-home .all-bloc .textA {
  padding: 0 40px 40px 40px;
}
#presentation-home .all-bloc .textA h2.h2textA {
  font-size: 25px;
  font-style: italic;
  color: #8C4653;
  margin-bottom: 25px;
}
#presentation-home .all-bloc .textA h2.h2textA span {
  display: block;
  color: #1a1a18;
  font-size: 48px;
  font-style: normal;
}
#presentation-home .all-bloc .textA p {
  font-size: 17px;
  line-height: 28px;
}
#presentation-home .all-bloc .textA a.lire-plus {
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
}
#presentation-home .all-bloc .textA a.lire-plus:after {
  display: inline-block;
  content: '';
  width: 35px;
  height: 1px;
  background: #8C4653;
  margin-bottom: 5px;
  margin-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#presentation-home .all-bloc .textA a.lire-plus:hover {
  color: #6a353f;
}
#presentation-home .all-bloc .textA a.lire-plus:hover:after {
  margin-left: 5px;
  background: #ccc;
}
#presentation-home .all-bloc .textB {
  padding: 40px 40px 0 40px;
}
#presentation-home .all-bloc .textB h2.h2textB {
  font-size: 25px;
  font-style: italic;
  color: #8C4653;
  margin-bottom: 25px;
}
#presentation-home .all-bloc .textB h2.h2textB span {
  display: block;
  color: #1a1a18;
  font-size: 48px;
  font-style: normal;
}
#presentation-home .all-bloc .textB p {
  font-size: 17px;
  line-height: 28px;
}
#presentation-home .all-bloc .textB a.lire-plus {
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
}
#presentation-home .all-bloc .textB a.lire-plus:after {
  display: inline-block;
  content: '';
  width: 35px;
  height: 1px;
  background: #8C4653;
  margin-bottom: 5px;
  margin-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#presentation-home .all-bloc .textB a.lire-plus:hover {
  color: #6a353f;
}
#presentation-home .all-bloc .textB a.lire-plus:hover:after {
  margin-left: 5px;
  background: #ccc;
}
#presentation-home .all-bloc .imgIntro {
  width: 100%;
  height: 750px;
  background: url('../img/big_img_test.jpg') no-repeat center center;
  background-size: cover;
}
@media screen and (max-width: 992px) {
  #presentation-home .all-bloc .textA {
    padding: 0 0 40px 0;
  }
  #presentation-home .all-bloc .textB {
    padding: 40px 0 0 0;
  }
  #presentation-home .all-bloc .imgIntro {
    height: 650px;
  }
}
@media screen and (max-width: 768px) {
  #presentation-home .all-bloc {
    display: flex;
    flex-wrap: wrap;
  }
  #presentation-home .all-bloc .textB {
    order: 1;
    padding: 40px 0 40px 0;
  }
  #presentation-home .all-bloc .imgIntro {
    order: 2;
  }
}
@media screen and (max-width: 450px) {
  #presentation-home .all-bloc .imgIntro {
    height: 550px;
  }
}
@media screen and (max-width: 350px) {
  #presentation-home {
    padding: 60px 0;
  }
  #presentation-home .presentation {
    padding-bottom: 60px;
  }
  #presentation-home .presentation h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  #presentation-home .all-bloc .textA h2.h2textA {
    font-size: 22px;
    margin-bottom: 20px;
  }
  #presentation-home .all-bloc .textA h2.h2textA span {
    font-size: 38px;
    line-height: 40px;
  }
  #presentation-home .all-bloc .textB h2.h2textB {
    font-size: 22px;
    margin-bottom: 20px;
  }
  #presentation-home .all-bloc .textB h2.h2textB span {
    font-size: 38px;
    line-height: 40px;
  }
  #presentation-home .all-bloc .imgIntro {
    height: 450px;
  }
}
/***********************************************************
******* PARALLAX HOME
***********************************************************/
.parallax-home {
  height: 450px;
  text-align: center;
  background: url(../img/big_img_test.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}
.parallax-home .parallax-black {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
}
/** Correctif fixed sur version mobile **/
@media only screen and (min-width: 992px) {
  .parallax-home {
    background-attachment: fixed;
  }
}
#parallax-actus {
  position: relative;
  margin-top: -200px;
  margin-bottom: 80px;
}
#parallax-actus .parallax-content {
  background: #faf6ed;
  padding: 80px 60px 70px 60px;
  text-align: center;
}
#parallax-actus .parallax-content h2.title-newsG {
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 35px;
}
#parallax-actus .parallax-content #owl-actus .item {
  margin-bottom: 35px;
}
#parallax-actus .parallax-content #owl-actus .item h3.title-news {
  color: #8C4653;
  font-size: 35px;
  line-height: 37px;
  margin-bottom: 30px;
}
#parallax-actus .parallax-content #owl-actus .item .content-news {
  padding-bottom: 20px;
}
#parallax-actus .parallax-content #owl-actus .item .content-news p {
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 0;
}
#parallax-actus .parallax-content #owl-actus .item a.lire-plus {
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  padding: 4px 0 1px 4px;
}
#parallax-actus .parallax-content #owl-actus .item a.lire-plus:hover {
  background: #8C4653;
  color: #fff;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-dots .owl-dot span {
  margin: 0 3px;
  background: #ccc;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-dots .owl-dot.active span {
  background: #8C4653;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-dots .owl-dot.active:hover span {
  background: #8C4653;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-dots .owl-dot:hover span {
  background: #333;
}
#parallax-actus .parallax-content #owl-actus.owl-theme .owl-dots .owl-dot:focus {
  outline: none;
}
@media screen and (max-width: 992px) {
  .parallax-home {
    height: 350px;
  }
  #parallax-actus .parallax-content {
    padding: 60px 40px 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  #parallax-actus .parallax-content {
    padding: 50px 20px 30px 20px;
  }
  #parallax-actus .parallax-content #owl-actus .item {
    margin-bottom: 30px;
  }
  #parallax-actus .parallax-content #owl-actus .item h3.title-news {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  #parallax-actus .parallax-content #owl-actus .item .content-news {
    padding-bottom: 15px;
  }
}
/***********************************************************
******* HAUT PAGE
***********************************************************/
.haut-page-bg {
  height: 450px;
  text-align: center;
  background: url(../img/big_img_test.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}
.haut-page-bg .parallax-black {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  height: 100%;
}
/** Correctif fixed sur version mobile **/
@media only screen and (min-width: 992px) {
  .haut-page-bg {
    background-attachment: fixed;
  }
}
#haut-page {
  position: relative;
  margin-top: -200px;
  margin-bottom: 80px;
}
#haut-page .parallax-content {
  background: #faf6ed;
  padding: 80px 60px 70px 60px;
  text-align: center;
}
#haut-page .parallax-content h1.title-main {
  color: #1a1a18;
  font-size: 40px;
  line-height: 46px;
}
#haut-page .parallax-content h1.title-main span {
  display: block;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 35px;
}
#haut-page .parallax-content .subtitle {
  margin-top: 35px;
}
#haut-page .parallax-content .subtitle p {
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 0;
}
#haut-page .parallax-content .subtitle span.horaires {
  text-transform: lowercase;
}
.haut-page-bg-tunnel {
  height: 210px;
}
#haut-page.haut-page-tunnel {
  margin-top: -150px;
  margin-bottom: 20px;
}
#haut-page.haut-page-tunnel .parallax-content h1.title-main span {
  display: none;
}
#haut-page.haut-page-tunnel .parallax-content .subtitle {
  margin-top: 10px;
}
@media screen and (max-width: 992px) {
  .haut-page-bg {
    height: 350px;
  }
  #haut-page .parallax-content {
    padding: 60px 40px 50px 40px;
  }
  #haut-page .parallax-content h1.title-main {
    font-size: 35px;
    line-height: 38px;
  }
  #haut-page .parallax-content h1.title-main span {
    margin-bottom: 20px;
  }
  #haut-page .parallax-content .subtitle {
    margin-top: 20px;
  }
  #haut-page .parallax-content .subtitle p {
    font-size: 17px;
    line-height: 25px;
  }
  .haut-page-bg-tunnel {
    height: 210px;
  }
}
@media screen and (max-width: 768px) {
  #haut-page .parallax-content {
    padding: 50px 30px 50px 30px;
  }
  #haut-page .parallax-content h1.title-main {
    font-size: 32px;
    line-height: 34px;
  }
}
@media screen and (max-width: 575px) {
  .haut-page-bg {
    height: 215px;
  }
  #haut-page {
    margin-bottom: 60px;
  }
  .haut-page-bg-tunnel {
    height: 165px;
  }
}
/***********************************************************
******* INT
***********************************************************/
#int {
  padding: 20px 0 100px 0;
}
#int h2 {
  font-size: 32px;
  line-height: 36px;
  color: #8C4653;
  margin-bottom: 6px;
}
#int h3 {
  font-size: 26px;
  line-height: 30px;
  color: #1a1a18;
  margin-bottom: 6px;
}
#int .subtitle {
  font-size: 1.3em;
}
#int p + h2 {
  margin-top: 30px;
}
#int h2 + h3 {
  margin-top: 15px;
}
#int .infos-commande {
  margin-bottom: 40px;
  text-align: center;
}
#int .infos-commande a.js-scrollTo {
  color: #8C4653;
  cursor: pointer;
  font-weight: 700;
}
#int .infos-commande a.js-scrollTo:hover {
  color: #1a1a18;
}
#int .infos-commande a.btn-commander {
  display: inline-block;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: #8C4653;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  padding: 12px 8px 8px 10px;
}
#int .infos-commande a.btn-commander:hover {
  background: #333;
}
#int .actu {
  text-align: center;
}
#int .actu p {
  margin: 0;
}
#int .actu + .actu {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #faf6ed;
}
#int .row-photo-left .colTexte,
#int .row-photo-right .colTexte {
  padding: 50px 15px;
}
#int .row-photo-left .colTexte p,
#int .row-photo-right .colTexte p {
  text-align: justify;
  margin-bottom: 0;
}
#int .row-photo-left .colImage,
#int .row-photo-right .colImage {
  background: url('../img/big_img_test.jpg') no-repeat center center;
  background-size: cover;
}
#int .row-photo-left .colImage {
  border-left: 15px solid #fff;
}
#int .row-photo-left .colTexte {
  padding-left: 50px;
}
#int .row-photo-right .colImage {
  border-right: 15px solid #fff;
}
#int .row-photo-right .colTexte {
  padding-right: 50px;
}
#int .box-content {
  width: 250px;
  margin: 0 auto;
}
#int .box-content:after {
  display: block;
  clear: both;
  content: "";
}
#int .box-content dt {
  float: left;
  clear: left;
  margin: 0;
}
#int .box-content dd {
  float: right;
  clear: right;
  margin: 0;
}
@media screen and (max-width: 767px) {
  #int .row-photo-left .colTexte,
  #int .row-photo-right .colTexte {
    padding: 0 15px 15px 15px !important;
  }
  #int .row-photo-left .colTexte p,
  #int .row-photo-right .colTexte p {
    text-align: left;
  }
  #int .row-photo-left .colImage,
  #int .row-photo-right .colImage {
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
    height: 400px;
    margin-bottom: 30px;
  }
  #int .row-photo-left .order-1 {
    order: 2;
  }
  #int .row-photo-left .order-2 {
    order: 1;
  }
}
@media screen and (max-width: 575px) {
  #int {
    padding: 0 0 60px 0;
  }
  #int .row-photo-left .colImage,
  #int .row-photo-right .colImage {
    height: 280px;
  }
}
/***********************************************************
******* SIDEBAR
***********************************************************/
#sidebar {
  position: sticky;
  top: 30px;
  text-align: center;
}
#sidebar .title-sidebar {
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 15px;
  color: #333;
}
#sidebar a {
  color: #8C4653;
  font-weight: 700;
}
#sidebar a:hover {
  color: #1a1a18;
}
#sidebar a.panier {
  display: block;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 8px;
}
#sidebar a.panier:hover {
  color: #1a1a18;
}
#sidebar a.account,
#sidebar a.logout {
  display: inline-block;
  color: #8C4653;
  font-weight: 700;
  font-size: 15px;
}
#sidebar a.account:hover,
#sidebar a.logout:hover {
  color: #1a1a18;
}
#sidebar a.btn-commander {
  display: inline-block;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: #8C4653;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
  padding: 12px 8px 8px 10px;
}
#sidebar a.btn-commander:hover {
  background: #333;
}
#sidebar hr {
  border-color: #faf6ed;
}
#sidebar .box-content {
  width: auto;
  margin: inherit;
}
@media screen and (max-width: 992px) {
  #sidebar {
    padding-top: 40px;
  }
}
/***********************************************************
******* .WINE
***********************************************************/
.wine {
  border: 1px solid #faf6ed;
  padding: 30px;
}
.wine .content-main {
  display: flex;
  flex-wrap: nowrap;
}
.wine .content-main .photo {
  position: relative;
  width: 250px;
  height: max-content;
  text-align: center;
}
.wine .content-main .photo .photo-wine {
  position: relative;
  z-index: 2;
  max-width: 130px;
  margin: 0 auto;
}
.wine .content-main .photo .rond {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -125px;
  width: 250px;
  height: 250px;
  border-radius: 250px;
  background: url('../img/bg-wine.png') repeat;
}
.wine .content-main .text {
  width: calc(100% - 250px);
  padding-left: 30px;
}
.wine .content-main .text h2.title-wine {
  font-size: 32px;
  line-height: 34px;
  margin-bottom: 20px;
  color: #1a1a18;
}
.wine .content-main .text h2.title-wine span.year {
  display: inline-block;
  background: #8C4653;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  padding: 4px 6px 2px 6px;
}
.wine .content-main .text .resume {
  margin-bottom: 15px;
}
.wine .content-main .text .resume p {
  margin-bottom: 8px;
}
.wine .content-main .text .voir-plus {
  display: block;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 20px;
}
.wine .content-main .text .voir-plus:before {
  content: attr(data-vplabel);
}
.wine .content-main .text .voir-plus:after {
  display: inline-block;
  content: '';
  width: 35px;
  height: 1px;
  background: #8C4653;
  margin-bottom: 5px;
  margin-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.wine .content-main .text .voir-plus:hover {
  color: #6a353f;
}
.wine .content-main .text .voir-plus:hover:after {
  margin-left: 5px;
  background: #ccc;
}
.wine .content-main .text .voir-moins {
  display: block;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 20px;
}
.wine .content-main .text .voir-moins:before {
  content: attr(data-vmlabel);
}
.wine .content-main .text .voir-moins:after {
  display: inline-block;
  content: '';
  width: 35px;
  height: 1px;
  background: #8C4653;
  margin-bottom: 5px;
  margin-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.wine .content-main .text .voir-moins:hover {
  color: #6a353f;
}
.wine .content-main .text .voir-moins:hover:after {
  margin-left: 5px;
  background: #ccc;
}
.wine .content-main .text .awards {
  background: #faf6ed;
  padding: 15px 15px 12px 15px;
  margin-bottom: 20px;
}
.wine .content-main .text .awards .title-awards {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 8px;
  text-decoration: underline;
}
.wine .content-main .text .awards p {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 0;
}
.wine .content-main .text .btn-panier {
  display: inline-block;
  background: #8C4653;
  border: 0;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  line-height: 42px;
  height: 40px;
  width: 260px;
  text-align: center;
  margin-top: 20px;
  -webkit-appearance: inherit;
}
.wine .content-main .text .btn-panier:hover {
  background: #333;
}
.wine .content-main .text .others-btn {
  position: relative;
}
.wine .content-main .text .others-btn .photo-plus,
.wine .content-main .text .others-btn .sheet {
  display: block;
  position: absolute;
  top: -40px;
  height: 40px;
  width: 40px;
  background: rgba(140, 70, 83, 0.6);
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  cursor: pointer;
}
.wine .content-main .text .others-btn .photo-plus:hover,
.wine .content-main .text .others-btn .sheet:hover {
  background: #333;
}
.wine .content-main .text .others-btn .photo-plus {
  left: 264px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.wine .content-main .text .others-btn .photo-plus span {
  display: none;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;
}
.wine .content-main .text .others-btn .photo-plus a.fancybox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wine .content-main .text .others-btn .photo-plus .hidden-all {
  display: none;
}
.wine .content-main .text .others-btn .sheet {
  left: 308px;
}
.wine .content-main .text .others-btn-NOSTOCK .photo-plus,
.wine .content-main .text .others-btn-NOSTOCK .sheet {
  position: relative !important;
  display: inline-block !important;
  top: inherit !important;
  left: inherit !important;
}
.wine .content-main .text .description-plus {
  margin-top: 15px;
}
.wine .content-main .text .description-plus p {
  margin-bottom: 0;
}
.wine .content-main .text .description-plus p + p {
  margin-top: 8px;
}
.wine .content-main .text .description-plus h3 {
  font-size: 19px;
  line-height: 21px;
  font-weight: 700;
  margin-bottom: 5px;
}
.wine .content-main .text .description-plus p + h3 {
  margin-top: 15px;
}
.wine + .wine {
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .wine {
    padding: 30px 20px;
  }
  .wine .content-main .photo {
    width: 150px;
  }
  .wine .content-main .photo .photo-wine {
    max-width: 100px;
  }
  .wine .content-main .photo .rond {
    margin-top: -75px;
    margin-left: -75px;
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
  .wine .content-main .text {
    width: calc(100% - 150px);
    padding-left: 15px;
  }
  .wine .content-main .text h2.title-wine {
    font-size: 28px;
    line-height: 30px;
  }
  .wine .content-main .text .others-btn .photo-plus,
  .wine .content-main .text .others-btn .sheet {
    display: inline-block;
    position: relative;
    top: inherit;
    margin-top: 4px;
  }
  .wine .content-main .text .others-btn .photo-plus {
    left: inherit;
  }
  .wine .content-main .text .others-btn .sheet {
    left: inherit;
  }
}
@media screen and (max-width: 500px) {
  .wine {
    padding: 20px;
  }
  .wine .content-main {
    flex-wrap: wrap;
  }
  .wine .content-main .photo {
    display: none;
  }
  .wine .content-main .text {
    width: 100%;
    padding-left: 0;
  }
  .wine .content-main .text h2.title-wine {
    font-size: 23px;
    line-height: 25px;
  }
  .wine .content-main .text h2.title-wine span.year {
    font-size: 14px;
    line-height: 14px;
  }
  .wine .content-main .text .voir-plus:after {
    display: none;
  }
  .wine .content-main .text .voir-moins:after {
    display: none;
  }
  .wine .content-main .text .btn-panier {
    font-size: 14px;
    width: 220px;
  }
  .wine .content-main .text .others-btn .photo-plus {
    width: auto;
    padding: 0 8px;
  }
  .wine .content-main .text .others-btn .photo-plus span {
    display: inline-block;
  }
}
/***********************************************************
******* MODAL PANIER
***********************************************************/
#ModalPanier .modal-header .modal-title {
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  padding-top: 8px;
}
#ModalPanier .modal-body .name-add {
  font-size: 28px;
  line-height: 30px;
  color: #1a1a18;
  margin-top: 10px;
}
#ModalPanier .modal-body .name-add span {
  display: inline-block;
  background: #8C4653;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  padding: 4px 6px 2px 6px;
}
#ModalPanier .modal-footer {
  justify-content: flex-start;
}
@media screen and (max-width: 450px) {
  #ModalPanier .modal-footer {
    flex-wrap: wrap;
  }
  #ModalPanier .modal-footer .btn {
    display: block;
    width: 100% !important;
  }
  #ModalPanier .modal-footer > :not(:last-child) {
    margin-right: 0 !important;
  }
  #ModalPanier .modal-footer > :not(:first-child) {
    margin-left: 0 !important;
  }
  #ModalPanier .btn + .btn {
    margin-top: 4px;
  }
}
/***********************************************************
******* TUNNEL COMPTE
***********************************************************/
#tunnel-compte .table {
  margin: 0;
}
#tunnel-compte .table thead {
  background: #f5f5f5;
}
#tunnel-compte .table th {
  vertical-align: middle;
  padding: 10px 10px 6px 10px;
}
#tunnel-compte .table td {
  vertical-align: middle;
}
#tunnel-compte .table-total {
  background: #f5f5f5;
}
#tunnel-compte .table-total th,
#tunnel-compte .table-total td {
  vertical-align: middle;
  padding: 10px 10px 6px 10px;
}
#tunnel-compte .table-total td {
  width: 280px;
}
#tunnel-compte .table-total .btn-link {
  padding: 0;
  margin: 0 5px;
  border: 0;
  background: transparent;
  outline: none;
  color: rgba(140, 70, 83, 0.5);
}
#tunnel-compte .table-total .btn-link:hover,
#tunnel-compte .table-total .btn-link:focus {
  color: #8C4653;
}
#tunnel-compte .form-group.row {
  margin: 0 0 15px 0;
}
#tunnel-compte .btn-copie-infos {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 35px;
  border: 1px solid #333;
  padding: 3px 0 0 6px;
  cursor: pointer;
}
#tunnel-compte .btn-copie-infos:hover {
  border-color: #8C4653;
  color: #8C4653;
}
#tunnel-compte h2.confirmTitle {
  font-size: 25px;
  line-height: 27px;
}
.popover {
  min-width: 180px !important;
}
@media screen and (max-width: 991px) {
  #tunnel-compte .table-total td {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .marginTopRegister {
    margin-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  #tunnel-compte .row-btn .text-left {
    text-align: right !important;
  }
  #tunnel-compte .form-group.row {
    margin: 0 -15px 15px -15px;
  }
}
/***********************************************************
******* OFFRES
***********************************************************/
#offres .all-offre .text h2.h2text {
  font-size: 25px;
  font-style: italic;
  color: #8C4653;
  margin-bottom: 25px;
}
#offres .all-offre .text h2.h2text span {
  display: block;
  color: #1a1a18;
  font-size: 48px;
  font-style: normal;
}
#offres .all-offre .text .time-price {
  display: inline-block;
  background: #8C4653;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 4px 3px 4px;
}
#offres .all-offre .text p,
#offres .all-offre .text ul {
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 0;
}
#offres .all-offre .text p + ul {
  margin-top: 0;
}
#offres .all-offre .text a.lire-plus {
  display: block;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 13px;
  margin-top: 20px;
}
#offres .all-offre .text a.lire-plus:after {
  display: inline-block;
  content: '';
  width: 35px;
  height: 1px;
  background: #8C4653;
  margin-bottom: 5px;
  margin-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#offres .all-offre .text a.lire-plus:hover {
  color: #6a353f;
}
#offres .all-offre .text a.lire-plus:hover:after {
  margin-left: 5px;
  background: #ccc;
}
#offres .all-offre .imgintro {
  background: url('../img/big_img_test.jpg') no-repeat center center;
  background-size: cover;
  border-left: 15px solid #fff;
  border-right: 15px solid #fff;
}
@media screen and (max-width: 768px) {
  #offres .all-offre .imgintro {
    height: 250px;
    margin-top: 15px;
  }
}
#offres .all-offre + .all-offre {
  margin-top: 50px;
}
/***********************************************************
******* CARD COLUMNS
***********************************************************/
.card-columns {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  orphans: 1;
  widows: 1;
}
.card-columns .card {
  border-radius: 0;
  border: 0;
  margin-bottom: 20px;
  background: #faf6ed;
}
.card-columns .card:hover img {
  opacity: 0.7;
}
.card-columns .card img {
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.card-columns .card a {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    orphans: 1;
    widows: 1;
  }
}
@media screen and (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    orphans: 1;
    widows: 1;
  }
}
/***********************************************************
******* FORMULAIRES (avec Parsley)
***********************************************************/
.coordonnees-info {
  position: relative;
  text-align: center;
  min-height: 250px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.coordonnees-info .content {
  position: relative;
  z-index: 2;
  height: 100%;
}
.coordonnees-info .content .icon {
  color: #333;
  font-size: 30px;
  margin-bottom: 10px;
  height: 30px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.coordonnees-info .content h2.info {
  display: block;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #8C4653 !important;
  font-weight: 700;
  font-size: 18px !important;
  line-height: 18px !important;
  margin-bottom: 10px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.coordonnees-info .content .text {
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.coordonnees-info .content .text i {
  font-size: 11px;
}
.coordonnees-info .bg-round {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -125px;
  width: 250px;
  height: 250px;
  border-radius: 250px;
  background: url(../img/bg-wine.png) repeat;
}
a.coordonnees-info:hover .content .icon {
  color: #8C4653;
}
a.coordonnees-info:hover .content h2.info {
  color: #333 !important;
}
a.coordonnees-info:hover .content .text {
  color: #8C4653;
}
@media screen and (max-width: 991px) {
  .coordonnees-info {
    min-height: 210px;
  }
  .coordonnees-info .bg-round {
    margin-top: -105px;
    margin-left: -105px;
    width: 210px;
    height: 210px;
    border-radius: 210px;
  }
}
@media screen and (max-width: 767px) {
  .coordonnees-info {
    min-height: inherit;
  }
  .coordonnees-info .margin-top25 {
    margin-top: 10px !important;
  }
  .coordonnees-info .bg-round {
    display: none;
  }
}
.margin-topFORM50 {
  margin-top: 50px;
}
@media screen and (max-width: 575px) {
  .margin-topFORM50 {
    margin-top: 0;
  }
}
.gmap {
  height: 100%;
}
.gmap iframe {
  height: 100%;
}
label {
  margin: 0;
}
.col-form-label {
  text-align: right;
}
@media screen and (max-width: 575px) {
  .col-form-label {
    text-align: left;
    padding: 0 15px !important;
  }
}
textarea.form-control {
  height: auto;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #8C4653;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(140, 70, 83, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(140, 70, 83, 0);
}
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.parsley-success {
  border: solid 1px #ccc !important;
  background: #fff !important;
  color: #333 !important;
}
.parsley-error {
  border: solid 1px #AA102A !important;
  background: rgba(170, 16, 42, 0.2) !important;
  color: #000 !important;
}
.parsley-errors-list {
  color: #AA102A;
  list-style: none;
  padding: 0;
}
.red {
  color: #8C4653;
  list-style: none;
  padding: 0;
}
#form_contact .required {
  color: #333;
  list-style: none;
  padding: 0;
}
#form_contact .success {
  color: #333;
}
#form_contact label.required:after {
  content: " *";
  color: #8C4653;
  position: absolute;
  margin: 0 0 0 2px;
  font-size: 14px;
  padding: 0 5px 0 0;
}
.ui-datepicker .ui-datepicker-title select {
  color: #333;
}
.parsley-error::-webkit-input-placeholder {
  color: #333;
}
.parsley-error:-moz-placeholder {
  color: #333;
}
.parsley-error::-moz-placeholder {
  color: #333;
}
.parsley-error:-ms-input-placeholder {
  color: #333;
}
/***********************************************************
******* FOOTER
***********************************************************/
#footer {
  padding-top: 15px;
}
#footer a {
  color: #333;
}
#footer a:hover {
  color: #8C4653;
}
#footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#footer ul li a.nav-link {
  padding: 0;
}
#footer .footer-up .logo-footer {
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 55px;
}
#footer .footer-middle .title-footer {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #8C4653;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 15px;
}
#footer .footer-middle .content p {
  margin-bottom: 0;
}
#footer .footer-middle a i {
  font-size: 11px;
}
#footer .footer-down {
  padding-top: 60px;
}
#footer .footer-down i {
  font-size: 20px;
  margin-top: 5px;
}
#footer .footer-down .img-vigneron {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 15px;
}
#footer .bg-footer {
  background: url('../img/bg-footer.jpg') no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 280px;
}
@media screen and (max-width: 768px) {
  #footer .footer-up .logo-footer {
    max-width: 100px;
    padding-bottom: 0;
  }
  #footer .footer-middle .col-12 {
    padding-top: 30px;
  }
  #footer .footer-middle .title-footer {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #footer .bg-footer {
    height: 200px;
  }
}
@media screen and (max-width: 576px) {
  #footer .footer-middle .col-12 {
    padding-top: 0;
  }
  #footer .bg-footer {
    height: 120px;
  }
}
.help-block {
  color: #ff0000;
  font-size: 0.9em;
}
